@import 'constants';

$module: 'HoursProvidedDialog';

.#{$module} {
  .FDBIconButton {
    z-index: 1;
    color: $mono0 !important;
  }

  &__video-background {
    width: 100vw;
    height: 100vh;
  }

  &__video-overlay-container {
    display: grid;
    justify-items: center;
    grid-template-rows: 4fr 5fr 3fr;
  }
}
